<template>
    <!-- <meta name="viewport" content="width=device-width,initial-scale=1.0"> -->
    <div>
        <img class="header" src="imagen1.png">
    </div>

    <div class="button-div">
        <!-- <img class="afiche" src="afiche.png"> -->
        <div>
            <a class="fcc-btn" href="https://forms.gle/C191WaDvZYg6h2666">Inscríbete aquí</a>
        </div>
    </div>
    <div>
        <div class="title">
            <br /><br /><br />
            <h2>Mapeo Paisaje de Conservación Nahuelbuta Norte</h2>
            <!-- <iframe id="map-frame" src="http://www.paisajenahuelbutanorte.cl/mapa"> </iframe> -->
        </div>
        <div>
            <a class="fcc-btn" @click="this.$router.push({ name: 'BaseMap' })">Ver Mapa</a>
        </div>
    </div>
    <div>
        <img class="footer" src="imagen2.png">
    </div>
</template>

<script>
export default {
    name: 'HomePage'
}

</script>

<style>
.header {
    margin-top: 30px;
    width: 40%;
    /* padding:60px 0;
  text-align: center;
  background: #33cccc;
  color: white; */
}

.afiche {
    width: 30%;
    text-align: center;
    margin-bottom: 50px;
}

.button-div {
    margin-top: 30px;
    margin-bottom: 50px;
}

.fcc-btn {
    background-color: #199319;
    color: white;
    padding: 15px 40px;
    text-decoration: none;
    font-size: 20px;
    margin-top: 80px;
    margin-bottom: 80px;
}

.fcc-btn:hover {
    background-color: #223094;
}

.title h2 {
    margin-top: 10px;
    font-size: 36px;
}

.title {
    margin-top: 10px;
    /* font-size: 36px; */
}

#map-frame {
    /* margin-top: 100px; */
    margin-bottom: 50px;
    height: 600px;
    width: 800px;
}

.footer {
    margin-top: 100px;
    width: 40%;
}

/* Use a media query to add a breakpoint at 800px: */
@media screen and (max-width: 800px) {
  .header, .afiche, .footer {
    width: 90%; /* The width is 100%, when the viewport is 800px or smaller */
  }

  .button-div {
    margin-top: 20px;
    margin-bottom: 30px;
}
.fcc-btn {
    padding: 10px 20px;
    font-size: 16px;
    margin-top: 80px;
    margin-bottom: 70px;
}
}

</style>
